import React from 'react'
import Home from '../pages/Home'
import Login from '../pages/Authentication/Login'
import Privacy from '../pages/Utility/Privacy'
import Account from '../pages/User/Account'
import ManageAccount from '../pages/User/ManageAccount'
import Register from '../pages/Authentication/Register'
import ReturnLogin from '../pages/Returns/ReturnLogin'
import ReturnLanding from '../pages/Returns/ReturnLanding'
import ReturnLocations from '../pages/Returns/ReturnLocations'
import ReturnPhoto from '../pages/Returns/ReturnPhoto'
import Terms from '../pages/Utility/Terms'
import MarketplaceLocations from '../pages/Marketplace/MarketplaceLocations'
import NodeMenu from '../pages/Marketplace/NodeMenu'
import ViewDirectOrders from '../pages/User/ViewDirectOrders'
import DisputeBoxes from '../pages/User/DisputeBoxes'
import CheckoutPage from '../pages/Marketplace/CheckoutPage'
import ReturnAtDoor from '../pages/Returns/ReturnAtDoor'
import HowItWorks from '../pages/Utility/HowItWorks'
import WhyReuse from '../pages/Utility/WhyReuse'
import RestaurantPartners from '../pages/Utility/RestaurantPartners'
import RestaurantPartnersGetInfo from '../pages/Utility/RestaurantPartnersGetInfo'
import AboutUs from '../pages/Utility/AboutUs'
import EnterprisePartners from '../pages/Utility/EnterprisePartners'
import Press from '../pages/Utility/Press'
import RedirectPage from '../pages/Marketplace/RedirectPage'
import AdminLogin from '../pages/Admin/Login/AdminLogin'
import ForgotPassword from '../pages/Authentication/ForgotPassword'
import ResetPassword from '../pages/Authentication/ResetPassword'
import AdminHome from '../pages/Admin/AdminHome'
import AdminRestaurants from '../pages/Admin/Restaurant/Restaurants'
import AddRestaurant from '../pages/Admin/Restaurant/AddRestaurant'
import AdminReturnFeed from '../pages/Admin/Returns/ReturnFeed'
import RestaurantProfile from '../pages/Admin/Restaurant/RestaurantProfile'
import RestaurantPayments from '../pages/Admin/Restaurant/RestaurantPayments'
import AdminOrders from '../pages/Admin/Orders/Orders'
import ViewOneOrder from '../pages/Admin/Orders/ViewOneOrder'
import AdminReturns from '../pages/Admin/Returns/AdminReturns'
import AdminPromos from '../pages/Admin/Promos/AdminPromos'
import AdminCustomers from '../pages/Admin/Customers/Customers'
import ViewOneCustomer from '../pages/Admin/Customers/ViewOneCustomer'
import OAuthFlow from '../pages/Authentication/OAuthFlow'
import RestaurantMenuMap from '../pages/Admin/Restaurant/RestaurantMenuMap'
import AdminAnalytics from '../pages/Admin/Analytics/AdminAnalytics'
import AdminAnalyticsOrders from '../pages/Admin/Analytics/AnalyticsOrders'
import AdminAnalyticsEnvironmental from '../pages/Admin/Analytics/AnalyticsEnvironment'
import AdminCreateReturn from '../pages/Admin/Returns/CreateReturn'
import AdminRaas from '../pages/Admin/RAAS/AdminRaas'
import AdminRevenue from '../pages/Admin/Revenue/AdminRevenue'
import RevenueMonthly from '../pages/Admin/Revenue/RevenueMonthly'
import GiftCardPurchase from '../pages/Marketplace/GiftCardPurchase'
import PromoCreate from '../pages/Admin/Promos/PromoCreate'
import RestaurantCharges from '../pages/Admin/Restaurant/RestaurantCharges'
import RestaurantManualEntry from '../pages/Admin/Restaurant/RestaurantManualEntry'
import RestaurantAdminHome from '../pages/RestaurantAdmin/RestaurantAdminHome'
import RestaurantAdminOrders from '../pages/RestaurantAdmin/RestaurantAdminOrders'
import RestaurantViewTransaction from '../pages/RestaurantAdmin/RestaurantViewTransaction'
import RestaurantImpact from '../pages/RestaurantAdmin/RestaurantImpact'
import RestaurantViewPayments from '../pages/RestaurantAdmin/RestaurantViewPayments'
import RestaurantViewCharges from '../pages/RestaurantAdmin/RestaurantViewCharges'
import RestaurantAdminVerification from '../pages/RestaurantAdmin/RestaurantAdminVerification'
import RestaurantCreditCardPage from '../pages/RestaurantAdmin/RestaurantCreditCardPage'
import RestaurantCreditCardPagePhone from '../pages/RestaurantAdmin/RestaurantCreditCardPagePhone'
import RestaurantRequestContainers from '../pages/RestaurantAdmin/RestaurantRequestContainers'
import ReturnLandingPhone from '../pages/Returns/ReturnsLandingPhone'
import ReturnAtDoorPhone from '../pages/Returns/ReturnsAtDoorPhone'
import ReturnLocationsPhone from '../pages/Returns/ReturnLocationsPhone'
import ReturnPhotoPhone from '../pages/Returns/ReturnPhotoPhone'
import ThirdPartyPickup from '../pages/Returns/ThirdPartyPickup'
import CheckBoxesOutModule from '../pages/RestaurantAdmin/CheckBoxesOutModule'
import OrderUpload from '../pages/Admin/CSVUploads/OrderUpload'
import MenuUpload from '../pages/Admin/CSVUploads/MenuUpload'
import DropoffWidget from '../pages/Admin/Logistics/ThirdPartyLogistics/DropoffWidget'
import DropoffHome from '../pages/Admin/Logistics/ThirdPartyLogistics/DropoffHome'
import WholeFoods from '../pages/Marketplace/WholeFoodsLanding'
import ToastPressRelease from '../pages/Utility/ToastPressRelease'
import RouteCreator from '../pages/Admin/Logistics/RouteCreator'
import RewardsMarketplace from '../pages/Marketplace/RewardsMarketplace'
import OfficeAdminHome from '../pages/OfficeOrdering/OfficeAdminHome'
import OfficeOrders from '../pages/OfficeOrdering/OfficeOrders'
import OfficeCustomers from '../pages/OfficeOrdering/OfficeCustomers'
import OfficeViewOneOrder from '../pages/OfficeOrdering/OfficeViewOneOrder'
import OfficeRegistration from '../pages/OfficeOrdering/OfficeRegistration'
import OfficeViewOneCustomer from '../pages/OfficeOrdering/OfficeViewOneCustomer'
import OfficeLogin from '../pages/OfficeOrdering/OfficeLogin'
import OfficeAccount from '../pages/OfficeOrdering/OfficeAccount'
import ChowNowLanding from '../pages/Marketplace/ChowNowLanding'
import OfficeCreditCardPage from '../pages/OfficeOrdering/OfficeCreditCardPage'
import DropoffGently from '../pages/Admin/Logistics/ThirdPartyLogistics/DropOffGently'
import LatePayments from '../pages/User/LatePayments'
import ViewOneDropoff from '../pages/Admin/Logistics/ThirdPartyLogistics/ViewOneDropoff'
import WFMDashboard from '../pages/Admin/WFM/WFMDashboard'
import WFMEnvironmental from '../pages/Admin/WFM/AdminWFMEnviro'
import LogisticsHome from '../pages/Admin/Logistics/LogisticsHome'
import LogisticsDashboard from '../pages/Admin/Logistics/LogisticsDashboard'
import LogisticsPackingList from '../pages/Admin/Logistics/LogisticsPackingList'
import LogisticsInventory from '../pages/Admin/Logistics/LogisticsInventory'
import LogisticsMonthly from '../pages/Admin/Logistics/LogisticsMonthly'
import DZInternalFulfillment from '../pages/Admin/Logistics/DZInternalFulfillment'
import FranchiseeCharges from '../pages/Admin/Billing/FranchiseeCharges'
import RegisterCC from '../pages/Authentication/RegisterCC'
import RaasLanding from '../pages/Utility/RaasLanding'
import LatePaymentsPhone from '../pages/User/LatePaymentsPhone'
import SignUpPage1 from '../pages/Utility/SignUpFlow/SignUpPage1'
import SignUpPage2 from '../pages/Utility/SignUpFlow/SignUpPage2'
import SignUpPage3 from '../pages/Utility/SignUpFlow/SignUpPage3'
import SignUpPage4 from '../pages/Utility/SignUpFlow/SignUpPage4'
import SignUpPage5 from '../pages/Utility/SignUpFlow/SignUpPage5'
import LeadsDashboard from '../pages/Admin/Leads/LeadsDashboard'
import AmbassadorLanding from '../pages/Utility/AmbassadorLanding'
import RestaurantUsageData from '../pages/Admin/Restaurant/RestaurantUsageData'
import PickupHome from '../pages/Admin/Logistics/PickupHome'
import DropoffUberdirect from '../pages/Admin/Logistics/ThirdPartyLogistics/DropoffUberdirect'
import Pentest from '../pages/Utility/Pentest'
import DropOffFlot from '../pages/Admin/Logistics/ThirdPartyLogistics/DropOffFlot'
import MerchantBenefits from '../pages/Utility/MerchantBenefits'
import PlatformIntegrationHome from '../pages/RestaurantAdmin/PlatformIntegrationHome'
import PlatformInstructions from '../pages/RestaurantAdmin/PlatformInstructions'
import ViewOnePickup from '../pages/Admin/Logistics/ViewOnePickup'
import ViewOneLead from '../pages/Admin/Leads/ViewOneLead'
import RestaurantAdminAccount from '../pages/RestaurantAdmin/RestaurantAdminAccount'
import MerchantRestaurantInfo from '../pages/RestaurantAdmin/MerchantAccount/MerchantRestaurantInfo'
import MerchantNewLocations from '../pages/RestaurantAdmin/MerchantAccount/MerchantNewLocations'
import MerchantContacts from '../pages/RestaurantAdmin/MerchantAccount/MerchantContacts'
import CustomerReusablesShop from '../pages/ReusablesShop/CustomerReusablesShop'
import MerchantReusablesShop from '../pages/ReusablesShop/MerchantReusablesShop'
import ReusablesCheckoutPage from '../pages/ReusablesShop/ReusablesCheckoutPage'
import ReusablesShopDashboard from '../pages/Admin/ReusablesShop/ReusablesShopDashboard'
import OfficeCharges from '../pages/Admin/Offices/OfficeCharges'
import TextEmailOptOutPage from '../pages/Utility/TextEmailOptOutPage'
import OfficeAdminVerification from '../pages/OfficeOrdering/OfficeAdminVerification'
import SquareLanding from "../pages/Utility/SquareLanding";
import MembershipLanding from '../pages/Utility/MembershipLanding'
import SubscribersDashboard from '../pages/Admin/Subscribers/SubscribersDashboard'
import HowToAddMenuItem from "../pages/Utility/HowToAddMenuItem";


const userRoutes = [
	{ path: '/user/account', component: <Account /> },
	{ path: '/returns/pickup', component: <ReturnAtDoor /> },
	{ path: '/returns/third-party', component: <ThirdPartyPickup /> },
	{ path: '/returns/:nodeSlug', component: <ReturnPhoto /> },
	{ path: '/order/:orderId', component: <ViewDirectOrders /> },
	{ path: '/dispute/:transactionId', component: <DisputeBoxes /> },
	{ path: '/checkout', component: <CheckoutPage /> },
	{ path: '/marketplace/:nodeSlug', component: <NodeMenu /> },
	{ path: '/admin/login', component: <AdminLogin /> },
	{ path: '/admin/home', component: <AdminHome /> },
	{ path: '/admin/restaurants', component: <AdminRestaurants /> },
	{ path: '/admin/restaurants/payments', component: <RestaurantPayments /> },
	{ path: '/admin/restaurants/charges', component: <RestaurantCharges /> },
	{ path: '/admin/restaurants/:nodeSlug', component: <RestaurantProfile /> },
	{
		path: '/admin/restaurants/:nodeSlug/menu-map',
		component: <RestaurantMenuMap />,
	},
	{
		path: '/admin/restaurants/:nodeSlug/usage-data',
		component: <RestaurantUsageData />,
	},
	{ path: '/admin/restaurants/add', component: <AddRestaurant /> },
	{ path: '/admin/restaurants/manual', component: <RestaurantManualEntry /> },
	{ path: '/admin/customers', component: <AdminCustomers /> },
	{ path: '/admin/customers/:userId', component: <ViewOneCustomer /> },
	{ path: '/admin/orders', component: <AdminOrders /> },
	{ path: '/admin/orders/:orderId', component: <ViewOneOrder /> },
	{ path: '/admin/returns', component: <AdminReturns /> },
	{ path: '/admin/returns/create', component: <AdminCreateReturn /> },
	{ path: '/admin/return-feed', component: <AdminReturnFeed /> },
	{ path: '/admin/analytics', component: <AdminAnalytics /> },
	{ path: '/admin/revenue', component: <AdminRevenue /> },
	{ path: '/admin/billing', component: <FranchiseeCharges /> },
	{
		path: '/admin/revenue/monthly/:month/:year',
		component: <RevenueMonthly />,
	},
	{ path: '/admin/dropoff', component: <DropoffHome /> },
	{ path: '/admin/dropoff/:orderId', component: <ViewOneDropoff /> },
	{ path: '/admin/dropoff-create', component: <DropoffWidget /> },
	{
		path: '/admin/dropoff-create/gently',
		component: <DropoffGently />,
	},
	{
		path: '/admin/dropoff-create/uberdirect',
		component: <DropoffUberdirect />,
	},
	{
		path: '/admin/dropoff-create/flot',
		component: <DropOffFlot />,
	},
	{ path: '/admin/analytics/orders', component: <AdminAnalyticsOrders /> },
	{
		path: '/admin/analytics/environment',
		component: <AdminAnalyticsEnvironmental />,
	},
	{ path: '/admin/route-creator', component: <RouteCreator /> },
	{ path: '/admin/promos', component: <AdminPromos /> },
	{ path: '/admin/promo-create', component: <PromoCreate /> },
	{ path: '/admin/order-upload', component: <OrderUpload /> },
	{ path: '/admin/menu-upload', component: <MenuUpload /> },
	{ path: '/admin/raas', component: <AdminRaas /> },
	{ path: '/admin/office', component: <OfficeCharges /> },
	{ path: '/admin/wholefoods', component: <WFMDashboard /> },
	{
		path: '/admin/wholefoods/environmental',
		component: <WFMEnvironmental />,
	},
	{ path: '/admin/logistics', component: <LogisticsHome /> },
	{
		path: '/admin/logistics/packinglist',
		component: <LogisticsPackingList />,
	},
	{
		path: '/admin/logistics/packinglist/dashboard',
		component: <DZInternalFulfillment />,
	},
	{ path: '/admin/logistics/dashboard', component: <LogisticsDashboard /> },
	{ path: '/admin/logistics/inventory', component: <LogisticsInventory /> },
	{ path: '/admin/logistics/monthly', component: <LogisticsMonthly /> },
	{ path: '/admin/logistics/pickups', component: <PickupHome /> },
	{ path: '/admin/logistics/pickups/:orderId', component: <ViewOnePickup /> },
	{ path: '/admin/leads', component: <LeadsDashboard /> },
	{ path: '/admin/leads/:nodeSlug', component: <ViewOneLead /> },
	{ path: '/admin/reusables-shop', component: <ReusablesShopDashboard /> },
	{ path: '/admin/subscribers', component: <SubscribersDashboard /> },
	{ path: '/restaurant-admin/orders/', component: <RestaurantAdminOrders /> },
	{
		path: '/restaurant-admin/orders/:transactionId',
		component: <RestaurantViewTransaction />,
	},
	{ path: '/restaurant-admin/home/', component: <RestaurantAdminHome /> },
	{
		path: '/restaurant-admin/verification/',
		component: <RestaurantAdminVerification />,
	},
	{ path: '/restaurant-admin/impact/', component: <RestaurantImpact /> },
	{
		path: '/restaurant-admin/payments/',
		component: <RestaurantViewPayments />,
	},
	{
		path: '/restaurant-admin/charges/',
		component: <RestaurantViewCharges />,
	},
	{
		path: '/restaurant-admin/charges/credit-card-form',
		component: <RestaurantCreditCardPage />,
	},
	{
		path: '/restaurant-admin/orders/date/:startDateParam/:endDateParam',
		component: <RestaurantAdminOrders />,
	},
	{
		path: '/restaurant-admin/request-containers/',
		component: <RestaurantRequestContainers />,
	},
	{
		path: '/restaurant-admin/checkout-containers/',
		component: <CheckBoxesOutModule />,
	},
	{
		path: '/restaurant-admin/platform-integration/',
		component: <PlatformIntegrationHome />,
	},
	{
		path: '/restaurant-admin/platform-integration/:platform',
		component: <PlatformInstructions />,
	},
	{
		path: '/restaurant-admin/account/:nodeSlug',
		component: <RestaurantAdminAccount />,
	},
	{
		path: '/restaurant-admin/account/:nodeSlug/restaurant-info',
		component: <MerchantRestaurantInfo />,
	},
	{
		path: '/restaurant-admin/account/:nodeSlug/new-locations',
		component: <MerchantNewLocations />,
	},
	{
		path: '/restaurant-admin/account/:nodeSlug/contacts',
		component: <MerchantContacts />,
	},
	{
		path: '/office-ordering/home',
		component: <OfficeAdminHome />,
	},
	{
		path: '/office-ordering/orders',
		component: <OfficeOrders />,
	},
	{
		path: '/office-ordering/orders/:transactionId',
		component: <OfficeViewOneOrder />,
	},
	{
		path: '/office-ordering/employees',
		component: <OfficeCustomers />,
	},
	{
		path: '/office-ordering/employees/:phone',
		component: <OfficeViewOneCustomer />,
	},
	{
		path: '/office-ordering/account',
		component: <OfficeAccount />,
	},
	{
		path: '/office-ordering/account/payment-method',
		component: <OfficeCreditCardPage />,
	},
	{
		path: '/office-ordering/verification',
		component: <OfficeAdminVerification />,
	},
]

const authRoutes = [
	{ path: '/returns/locations', component: <ReturnLocations /> },
	{ path: '/', component: <Home /> },
	{ path: '/login', component: <Login /> },
	{ path: '/login/membership', component: <Login /> },
	{ path: '/sign-up', component: <Register /> },
	{ path: '/sign-up-b', component: <RegisterCC /> },
	{ path: '/sign-up/:nodeSlug/:adminPhone', component: <Register /> },
	{
		path: '/sign-up/office/:officeSlug/:adminPhone',
		component: <Register />,
	},
	{
		path: '/sign-up/membership',
		component: <Register />,
	},
	{ path: '/returns', component: <ReturnLanding /> },
	{
		path: '/returns/:returnByTextId/:phone',
		component: <ReturnLandingPhone />,
	},
	{
		path: '/returns/pickup/:returnByTextId/:phone',
		component: <ReturnAtDoorPhone />,
	},
	{
		path: '/returns/locations/:returnByTextId/:phone',
		component: <ReturnLocationsPhone />,
	},
	{
		path: '/returns/:nodeSlug/:returnByTextId/:phone',
		component: <ReturnPhotoPhone />,
	},
	{ path: '/returns/login', component: <ReturnLogin /> },
	{ path: '/privacy', component: <Privacy /> },
	{ path: '/terms', component: <Terms /> },
	{ path: '/howitworks', component: <HowItWorks /> },
	{ path: '/whyreuse', component: <WhyReuse /> },
	{ path: '/restaurantpartners', component: <RestaurantPartners /> },
	{
		path: 'restaurantpartners/getInfo',
		component: <RestaurantPartnersGetInfo />,
	},
	{ path: '/raas-landing', component: <RaasLanding /> },
	{ path: '/aboutus', component: <AboutUs /> },
	{ path: '/enterprise', component: <EnterprisePartners /> },
	{ path: '/press', component: <Press /> },
	{ path: '/marketplace/locations', component: <MarketplaceLocations /> },
	{
		path: '/marketplace/:thirdPartyFilter/locations',
		component: <MarketplaceLocations />,
	},
	{
		path: '/redirect/:nodeSlug',
		component: <RedirectPage />,
	},
	{ path: '/marketplace/:nodeSlug', component: <NodeMenu /> },
	{ path: '/checkout', component: <CheckoutPage /> },
	{ path: '/forgotpassword', component: <ForgotPassword /> },
	{ path: '/reset', component: <ResetPassword /> },
	{ path: '/:client/oauth/authorize', component: <OAuthFlow /> },
	{ path: '/giftCard', component: <GiftCardPurchase /> },
	{ path: '/wholefoods', component: <WholeFoods /> },
	{ path: '/toast-press-release', component: <ToastPressRelease /> },
	{ path: '/rewards-marketplace', component: <RewardsMarketplace /> },
	{
		path: '/office-ordering/sign-up',
		component: <OfficeRegistration />,
	},
	{
		path: '/office-ordering/login',
		component: <OfficeLogin />,
	},
	{ path: '/chownow', component: <ChowNowLanding /> },
	{ path: '/square-landing', component: <SquareLanding /> },
	{ path: '/late-payments', component: <LatePayments /> },
	{
		path: '/late-payments/:phone',
		component: <LatePaymentsPhone />,
	},
	{
		path: '/restaurant-admin/charges/credit-card-form/:userId/:phone',
		component: <RestaurantCreditCardPagePhone />,
	},
	{ path: '/restaurantpartners/sign-up/page-1', component: <SignUpPage1 /> },
	{ path: '/restaurantpartners/sign-up/page-2', component: <SignUpPage2 /> },
	{ path: '/restaurantpartners/sign-up/page-3', component: <SignUpPage3 /> },
	{ path: '/restaurantpartners/sign-up/page-4', component: <SignUpPage4 /> },
	{ path: '/restaurantpartners/sign-up/page-5', component: <SignUpPage5 /> },
	{ path: '/ambassador-program', component: <AmbassadorLanding /> },
	{ path: '/ydekm', component: <Pentest /> },
	{ path: '/merchant-benefits', component: <MerchantBenefits /> },
	{ path: '/reusables-shop/members', component: <CustomerReusablesShop /> },
	{ path: '/reusables-shop', component: <CustomerReusablesShop /> },
	{ path: '/reusables-shop/bulk', component: <MerchantReusablesShop /> },
	{ path: '/reusables-shop/checkout', component: <ReusablesCheckoutPage /> },
	{ path: '/opt-out/:phone', component: <TextEmailOptOutPage /> },
	{ path: '/membership', component: <MembershipLanding /> },
	{ path: '/menu-item-instructions', component: <HowToAddMenuItem /> },
]

export { userRoutes, authRoutes }
